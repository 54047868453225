<script setup lang="ts">
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import { ScheduledPayment } from '/~/composables/scheduled-payments/core/ScheduledPayment'
import ItemPaymentMethod from './item/item-payment-method.vue'
import { useScheduledPaymentItemComponent } from './item.component'

const props = defineProps<{
  payment: ScheduledPayment
}>()

const { accountPresentation } = useBankAccountForm()

const {
  order,
  isPaymentOrder,
  isStatementOrder,
  payee,
  isBankAccountPayee,
  isBpayPayee,
  icon,
  onDeleteClick,
  translate,
  formatBsb,
  formatDate,
  formatDollar,
  paymentMethods,
} = useScheduledPaymentItemComponent(props)
</script>

<template>
  <div class="relative mb-2.5 rounded-lg border bg-white px-4 text-sm">
    <div class="absolute -right-1 -top-2 divide-none">
      <base-button
        icon="heroicons/solid/trash"
        :size="32"
        :padding="8"
        :loading="payment.isLoading"
        class="rounded-full bg-red-700 text-white"
        alt="Delete scheduled payment"
        @click="onDeleteClick"
      />
    </div>
    <div class="divide-y">
      <div v-if="isPaymentOrder" class="flex items-center space-x-2.5 py-4">
        <div
          class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full text-white"
          :class="{
            'bg-eonx-neutral-600': isBankAccountPayee,
            'bg-brand-bpay': isBpayPayee,
          }"
        >
          <base-icon :svg="icon" :size="isBankAccountPayee ? 22 : 18" />
        </div>
        <div class="truncate text-base font-bold text-eonx-neutral-800">
          {{ payee.name }}
        </div>
      </div>
      <div class="flex-1 space-y-2 py-4">
        <template v-if="isBpayPayee">
          <div class="flex flex-wrap justify-between truncate">
            <span class="mr-4 text-eonx-neutral-600">Biller:</span>
            <span class="text-eonx-neutral-800">
              {{ payee.billerCode }}
            </span>
          </div>
          <div class="flex flex-wrap justify-between truncate">
            <span class="mr-4 text-eonx-neutral-600">Ref:</span>
            <span class="text-eonx-neutral-800">
              {{ payee.reference }}
            </span>
          </div>
        </template>
        <template v-else-if="isBankAccountPayee">
          <div class="flex flex-wrap justify-between">
            <span class="mr-4 text-eonx-neutral-600">
              {{ translate('bankAccount.detailsTitle') }}
            </span>
            <span class="text-eonx-neutral-800">
              <template v-if="accountPresentation.bsb">
                {{ formatBsb(payee.bsb || '') }},
              </template>
              {{ payee.accountNumber }}
            </span>
          </div>
          <div v-if="payee.abn" class="flex flex-wrap justify-between gap-4">
            <span class="mr-4 text-eonx-neutral-600">
              {{ translate('payment.businessNumber') }}
            </span>
            <span class="text-eonx-neutral-800">
              {{ payee.abn }}
            </span>
          </div>
        </template>
        <template v-else-if="isStatementOrder">
          <div class="flex items-center">
            <div class="mr-2.5 flex items-center justify-center">
              <payee-icon :size="7" :payee="payee" />
            </div>
            <div class="mb-[5px] sm:mb-0 sm:pr-5">
              <div class="w-[174px] truncate">
                <span class="text-base font-bold text-neutral-800">
                  {{ payee.name }}
                </span>
              </div>
              <div class="text-sm text-eonx-neutral-600">
                <span>
                  {{ formatDate('monthlong', order.createdAt) }} statement
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap justify-between">
            <span class="text-eonx-neutral-600">Account No.</span>
            <span class="text-eonx-neutral-800">
              {{ order.userStatementAccount.number }}
            </span>
          </div>
        </template>
        <div class="flex flex-wrap justify-between">
          <span class="mr-4 text-eonx-neutral-600">Scheduled date</span>
          <span class="text-eonx-neutral-800">
            {{ formatDate('daymonthyearnumeric', order.scheduledAt) }}
          </span>
        </div>
        <div class="flex flex-wrap justify-between">
          <span class="mr-4 text-eonx-neutral-600">Amount</span>
          <span class="text-eonx-neutral-800">
            {{ formatDollar(order.subtotal) }}
          </span>
        </div>
      </div>
      <div class="space-y-2 py-4">
        <div class="flex flex-wrap justify-between">
          <span class="mr-4 text-eonx-neutral-600">Payment method:</span>
          <span class="text-eonx-neutral-800">
            <item-payment-method
              v-for="method in paymentMethods"
              :key="method.id || method.number"
              :method="method"
            />
          </span>
        </div>
        <div class="flex flex-wrap justify-between">
          <span class="mr-4 text-eonx-neutral-600">Fees:</span>
          <span class="text-eonx-neutral-800">
            {{ formatDollar(order.fees.total) }}
          </span>
        </div>
        <div
          class="mt-2 w-full space-x-1 rounded-sm bg-blue-50 px-2 py-1.5 text-center text-xs leading-4 text-blue-700"
        >
          <base-icon
            class="inline-block h-4 w-4 flex-shrink-0 align-top text-blue-500"
            svg="heroicons/mini/information-circle"
            alt="info"
          />
          <span>Fees</span>
          <template v-for="(fee, index) in order.fees.items">
            <span :key="`${index}_prefix`">
              {{ index === 0 ? '-' : '+' }}
            </span>
            <span :key="index">
              {{ formatDollar(fee.amount) }} {{ fee.label.toLowerCase() }}
            </span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
