<script setup lang="ts">
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import {
  getBrandImage,
  PaymentMethodType,
} from '/~/composables/payment-methods'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import {
  useStatementsListItem,
  StatementAccount,
} from '/~/composables/statements'

const props = withDefaults(
  defineProps<{
    account?: StatementAccount | null
  }>(),
  {
    account: null,
  }
)

const { accountPresentation } = useBankAccountForm()

const {
  title,
  clientName,
  autoPayDay,
  paymentMethod,
  isAutoPayToggled,
  statementAccount,
  disableDirectDebit,
} = useStatementsListItem({ account: props.account })
</script>

<template>
  <div class="relative mb-5 flex flex-col rounded-xl border bg-white p-[15px]">
    <div class="absolute -right-[5px] -top-[5px]">
      <base-button
        icon="heroicons/solid/trash"
        :size="32"
        :padding="8"
        class="rounded-full bg-red-700 text-white"
        alt="disable direct debit"
        @click="disableDirectDebit"
      />
    </div>
    <h3 class="mb-5 border-b pb-5 text-base text-eonx-neutral-800">
      <div v-if="clientName" class="truncate">
        {{ clientName }}
      </div>
      <div v-if="statementAccount && statementAccount.number" class="truncate">
        Account no. {{ statementAccount.number }}
      </div>
    </h3>
    <div class="mb-2 flex items-center space-x-2.5">
      <payee-icon :size="8" />
      <h4 class="text-base font-bold text-eonx-neutral-800" :title="title">
        {{ title }}
      </h4>
    </div>
    <div class="mb-2 flex flex-row items-start justify-between text-sm">
      <div class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
        Set payment date
      </div>
      <div class="whitespace-nowrap text-eonx-neutral-800">
        {{ autoPayDay }}
      </div>
    </div>
    <div
      v-if="isAutoPayToggled"
      class="mb-2 flex items-center justify-between text-sm"
    >
      <p class="text-sm leading-none text-eonx-neutral-600">Payment method</p>
      <div v-if="paymentMethod">
        <p
          v-if="paymentMethod.type === PaymentMethodType.creditCard"
          class="text-eonx-neutral-800"
        >
          <img
            :src="getBrandImage(paymentMethod.brand)"
            :alt="paymentMethod.brand"
            class="mr-[5px] inline w-6"
          />
          <span>**** **** **** {{ paymentMethod.number.slice(-4) }}</span>
        </p>
        <p
          v-if="paymentMethod.type === PaymentMethodType.bankAccount"
          class="flex items-center text-eonx-neutral-800"
        >
          <base-icon
            svg="plain/bank-account-circle"
            :size="16"
            class="mr-[5px] text-eonx-neutral-600"
          />
          <span>
            <template v-if="accountPresentation.bsb">
              {{ paymentMethod.bsb }},
            </template>
            *** *** *{{ paymentMethod.accountNumber.slice(-2) }}
          </span>
        </p>
      </div>
      <div v-else>-</div>
    </div>
  </div>
</template>
