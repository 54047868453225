import { computed } from 'vue'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import { useScheduledPayments } from '/~/composables/scheduled-payments'

export function useScheduledPaymentItemComponent(props: {
  payment: ScheduledPayment
}) {
  const { deleteScheduledPayment } = useScheduledPayments()
  const { translate, formatBsb } = useLocalization()

  const order = computed(() => {
    return props.payment.order
  })
  const isPaymentOrder = computed(() => {
    return props.payment.orderType === FlowType.payment
  })
  const isStatementOrder = computed(() => {
    return props.payment.orderType === FlowType.statement
  })
  const payee = computed(() => {
    return order.value.payee
  })
  const isBankAccountPayee = computed(() => {
    return payee.value?.type === 'bankAccount'
  })
  const isBpayPayee = computed(() => {
    return payee.value?.type === 'bpay'
  })
  const icon = computed(() => {
    if (payee.value?.type === 'bpay') {
      return 'symbion/bpay'
    }

    return 'v2/custom/bank'
  })
  const paymentMethods = computed(() => {
    return order.value.scheduledPaymentMethods
  })

  function onDeleteClick() {
    const options = {
      props: {
        title: 'Delete scheduled transaction',
        description: `Are you sure you want to delete the scheduled transaction of ${formatDollar(
          props.payment.subtotal
        )} to ${payee.value.name} on ${formatDate(
          'daymonthyearnumeric',
          props.payment.scheduledAt
        )}`,
        icon: {
          path: 'heroicons/solid/exclamation-triangle',
          color: 'text-red-500',
        },
        width: 'xs',
        onConfirm: async () => {
          await deleteScheduledPayment(props.payment.number)
        },
      },
    }

    if (ui.mobile) {
      bottomSheet.show('confirm', options)
    } else {
      modal.show('confirm-v2', options)
    }
  }

  return {
    order,
    isPaymentOrder,
    isStatementOrder,
    payee,
    isBankAccountPayee,
    isBpayPayee,
    icon,
    onDeleteClick,
    translate,
    formatBsb,
    formatDate,
    formatDollar,
    paymentMethods,
  }
}
